<template>
  <v-card>
    <v-card-title class="headline"> {{ modalTitle }} </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field
            id="exam-mode-name"
            label="Nome:"
            v-model="newExamModeName"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn small text @click="closeModal">
        <v-icon> mdi-close </v-icon> Fechar
      </v-btn>
      <v-btn small color="success" @click="buttonAction()">
        <i class="fa fa-check"></i> Cadastrar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  name: "BrandForm",
  props: {
    mode: {
      type: String,
      required: true,
    },
    modalTitle: {
      type: String,
      required: true,
    },
  },
  computed: {},
  data: function () {
    return {
      newExamModeName: null,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    buttonAction() {
      if (this.mode == "insert") {
        this.create();
      } 
    },
    validate() {
      const errors = [];

      //verifica se o nome foi preenchido
      if (this.newExamModeName === null || this.newExamModeName === "")
        errors.push("Adicione um nome!");

      return errors;
    },
    afterCreate(response) {
      // console.log("Marca criada com sucesso!");

      this.$emit("insert-completed", response);

      this.newExamModeName = null;
    },
    async create() {
      try {
        const errors = this.validate();

        if (errors.length)
          errors.forEach((e) => {
            console.log(`${e}`)
          });

        const response = await this.$axios.post(`/exam-modes`, {
          name: this.newExamModeName,
        });

        this.afterCreate(response.data);
      } catch (error) {
        if (Array.isArray(error)) {
          error.forEach((e) => {
            console.log(`${e}`)
          });
        } else {
          console.log(`${error}`)
        }
      }
    },
  },
  created() {},
};
</script>

<style>
</style>