<template>
  <BaseForm
    :title="title"
    :mod="mod"
    :flat="flat"
    :divider="divider"
    :dialog="dialog"
    :loading="loading"
    @save="save"
    @close-dialog="$emit('close-dialog')"
  >
    <template #content>
      <v-container fluid>
        <v-row>
          <v-col
            ><v-select
              label="Tipo: "
              :items="[
                { text: 'Consultas', value: 'consultation' },
                { text: 'Exames', value: 'exam' },
              ]"
              v-model="form.type"
              :readonly="mod === 'update' || receivedType"
            ></v-select
          ></v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              class="required"
              v-model="form.name"
              label="Nome:"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              class="required"
              label="Duração"
              v-model="form.duration"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col
            ><v-select
              class="required"
              :items="[
                { text: 'Minutos', value: 'minutes' },
                { text: 'Horas', value: 'hours' },
              ]"
              v-model="form.durationTimeUnit"
            >
            </v-select
          ></v-col>
        </v-row>

        <v-row v-if="form.type === 'exam'">
          <v-col>
            <ExamModeSelect
              v-model="form.ExamModeId"
              :newExamModeOption="examModeOption"
              @new-exam-mode="openModeFormDialog"
              class="required"
            />
          </v-col>
        </v-row>

        <v-row v-if="form.type === 'exam'">
          <v-col>
            <SubspecialtySelect
              v-model="form.ExamSubspecialtyId"
              class="required"
            />
          </v-col>
        </v-row>
      </v-container>

      <!-- modal de cadastrar nova modalidade -->
      <v-dialog v-model="examModeFormDialog" max-width="400">
        <ExameModesForm
          :mode="'insert'"
          :modalTitle="'Cadastrar modalidade'"
          @close="examModeFormDialog = false"
          @insert-completed="afterInsertExamMode"
        />
      </v-dialog>
    </template>

    <template #actions></template>
  </BaseForm>
</template>

<script>
import formMixin from "@/mixins/formMixin";
import errorHandler from "@/helpers/error_handler";

import BaseForm from "@/components/template/BaseForm";
import ExameModesForm from "../ExamModes/ExamModesForm";

import ExamModeSelect from "@/components/template/FormElements/Masks/ExamModeSelect";
import SubspecialtySelect from "@/components/template/FormElements/Masks/SubspecialtySelect";

export default {
  components: {
    BaseForm,
    ExameModesForm,
    ExamModeSelect,
    SubspecialtySelect,
  },
  props: ["type"],
  mixins: [formMixin],
  directives: {},
  computed: {
    procedureId() {
      return this.$route.params.id;
    },
    receivedType() {
      return !!this.type;
    },
  },
  data() {
    return {
      loading: false,
      examModeFormDialog: false,
      examModeOption: {},
      form: {
        type: "exam",
        durationTimeUnit: "minutes",
      },
    };
  },
  watch: {
    type(newType) {
      this.form.type = newType;
    },
  },
  methods: {
    openModeFormDialog() {
      this.examModeFormDialog = true;
    },
    afterInsertExamMode(data) {
      this.examModeOption = { value: data.id, text: data.name };

      this.form.ExamModeId = data.id;

      this.examModeFormDialog = false;
    },
    save(actionAfterOperation) {
      const errors = this.validateForm();

      if (errors.length) {
        this.$root.$errorDialog(errors);
      } else {
        this[this.mod](actionAfterOperation);
      }
    },
    async insert(actionAfterOperation) {
      try {
        this.loading = true;

        const response = await this.$axios.post(`/procedures`, this.form);

        this.loading = false;

        this.afterInsertion(
          actionAfterOperation,
          {
            ...this.form,
            id: response.data.id,
          },
          {
            type: "exam",
          }
        );
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    async update(actionAfterUpdate) {
      try {
        this.loading = true;

        await this.$axios.put(`/procedures/${this.procedureId}`, this.form);
        this.loading = false;

        this.afterUpdate(actionAfterUpdate);
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    async loadProcedureData() {
      try {
        this.loading = true;

        const response = await this.$axios.get(
          `/procedures/${this.procedureId}`
        );

        this.setProcedureData(response.data);
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      } finally {
        this.loading = false;
      }
    },
    setProcedureData(data) {
      const { ExamReference, ...remainingData } = data;
      const formAux = remainingData;

      if (remainingData.type == "exam") {
        formAux.ExamSubspecialtyId = ExamReference.ExamSubspecialtyId;
        formAux.ExamModeId = ExamReference.ExamModeId;
      }

      this.form = formAux;
    },
    validateForm() {
      const errors = [];

      if (!this.form.name) errors.push("Nome obrigatório!");

      if (!this.form.type) errors.push("Tipo obrigatório!");

      if (this.form.type === "exam") {
        if (!this.form.ExamModeId) errors.push("Campo modalidade obrigatório!");

        if (!this.form.ExamSubspecialtyId)
          errors.push("Campo subespecialidade obrigatória!");
      }

      if (!this.form.duration) errors.push("Tempo da duração é obrigatório");

      if (!this.form.durationTimeUnit)
        errors.push("Unidade de tempo da duração é obrigatória");

      return errors;
    },
  },
  created() {
    if (this.type) this.form.type = this.type;

    if (this.mod === "update") this.loadProcedureData();
  },
};
</script>

<style>
.label-mask {
  font-size: 1.05rem;
}
</style>
